import React from "react";
import CentralOverview from "../../components/CentralOverview/CentralOverview";
import TaskToday from "../../components/TaskToday/TaskToday";
import WhiteCardWrapper from "../../components/UI/WhiteCardWrapper/WhiteCardWrapper";

const Overview = () => {
  return (
    // <div className="flex-aifsjcsb flexgap2">
    <div>
      <CentralOverview />
      {/* <WhiteCardWrapper>
        <TaskToday />
      </WhiteCardWrapper> */}
    </div>
  );
};

export default Overview;
