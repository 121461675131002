// MainDrawer.js
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import logout from "../../assets/LeftSideBarIcons/logout.png";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Button,
  Typography
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import medicalcircles from "../../assets/LeftSideBarIcons/medicalcircles.svg";
import { drawerWidth } from "../../config/constants";
import { useAuth } from "../../providers/AuthProvider";
import { menu } from "../Menu";
// import { menu2 } from "../Menu";

import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { useTheme } from "@material-ui/core/styles";
import { Divider, ListItemAvatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    borderLeft: "3px solid white !important",
  },
  menuItemIcon: {
    height: "FIT-CONTENT",
  },
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#252b49",
    // backgroundImage: 'linear-gradient(to bottom,#1c80ac,#95d6ff)',
    color: "white",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

function MainDrawer(props) {
  const { window } = props;

  const navigate = useNavigate();
  const [menuList, setMenuList] = useState(menu);
  // const [bottomList, setBottomList] = useState(menu2);
  const [activeIndex, setActiveIndex] = useState(false)

  const theme = useTheme();

  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname;

  const auth = useAuth();
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => { }, [path]);
  useEffect(() => { }, [path2]);


  const drawer = (
    <div style={{ padding: "1rem" }}>
      {/* <div className={classes.toolbar} /> */}

      <div>
        <Link to="/overview" style={{ display: "flex", flexDirection: "column" }}>
          <Box style={{ margin: "0rem auto 1rem" }}>
            <img src={medicalcircles} alt="MedicalCircles" />
          </Box>
        </Link>
      </div>

      {/* <List style={{ maxHeight: "58vh", overflowY: "auto" }}> */}
      <List>

        {menuList.map((menuItem, index) => (
          menuItem.hasAccess && (
            <React.Fragment key={index}>
              <ListItem
                onClick={() => {
                  if (menuItem.child.length === 0) {
                    console.log("Clicked on ListItem", menuItem.url);

                    if (menuItem.url === "/logout") {
                      auth.logout();
                    } else {
                      navigate(menuItem.url);
                    }
                  }

                  menuList[index].active = !menuItem.active;
                  setActiveIndex(index)
                  setMenuList([...menuList]);
                }}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    path === menuItem.rootpath ? "#246AF1" : "transparent",
                  "&:hover": {
                    backgroundColor: "#eee", // Add a different background color on hover
                  },
                }}
                button
                style={{ borderRadius: ".5rem", marginBottom: "0rem" }}
              >
                <ListItemIcon
                  style={{
                    // maxWidth: "1rem",
                    margin: "auto",
                  }}
                >
                  {path === menuItem.rootpath ? (
                    <img
                      style={{
                        width: "23px",
                        height: "auto",
                        color: path === menuItem.rootpath ? "black" : "#8E92BC",
                      }}
                      src={menuItem?.activeIcon}
                    />
                  ) : (
                    <img
                      style={{
                        width: "23px",
                        height: "auto",
                        color: path === menuItem.rootpath ? "black" : "#8E92BC",
                      }}
                      src={menuItem?.icon}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      // color: "#8E92BC",
                      color: path === menuItem.rootpath ? "white" : "#00172D",
                    },
                  }}
                  primary={menuItem.text}
                />
                {menuItem.child.length > 0 &&
                  (path === menuItem.rootpath ? (
                    <ExpandLessIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon style={{ color: "gray" }} />
                  ))}
              </ListItem>
              {!_.isEmpty(menuItem.child) && (
                <Collapse in={activeIndex === index} timeout="auto" unmountOnExit>
                  {menuItem.child.map((child, cindex) => (
                    <ListItem
                      onClick={() => {
                        if (child.url == "logout") {
                          auth.logout();
                        } else {
                          navigate(child.url);
                        }
                      }}
                      // activeClassName="active"
                      // sx={{
                      //   cursor: "pointer",
                      //   backgroundColor:
                      //     path2 === child.rootpath ? "red" : "transparent",
                      //   "&:hover": {
                      //     backgroundColor: "#eee", // Add a different background color on hover
                      //   },
                      // }}
                      exact
                      // to={child.url}
                      key={cindex}
                      button
                    >
                      <ListItemIcon>
                        {child.icon && (
                          <Avatar
                            variant="rounded"
                            style={{ width: "20px" }}
                            src={child.icon}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          fontSize: "13px",
                          fontWeight: 400,
                        }}
                        primaryTypographyProps={{
                          style: {
                            fontSize: "14px",
                            fontWeight: 400,
                            // fontStyle: path2 === child.rootpath ? 'italic' : '',
                            // color: "#8E92BC",
                            color:
                              path2 === child.rootpath ? "#EA0234" : "#246AF1",
                          },
                        }}
                        primary={
                          // <Typography>
                            child.text
                          // </Typography>

                        }
                      />

                    </ListItem>
                  ))}
                </Collapse>
              )}
            </React.Fragment>
          )
        ))}
        {/* <div style={{ marginTop: '80%' }}>
          <Divider />
          {bottomList.map((menuItem, index) => (
            menuItem.hasAccess && (
              <React.Fragment key={index}>
                <ListItem
                  onClick={() => {
                    if (menuItem.url === "/logout") {
                      auth.logout();
                    } else {
                      navigate(menuItem.url);
                    }
                    menuList[index].active = !menuItem.active;
                    setMenuList([...menuList]);
                  }}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      path === menuItem.rootpath ? "#246AF1" : "transparent",
                    "&:hover": {
                      backgroundColor: "#eee", // Add a different background color on hover
                    },
                  }}
                  button
                  style={{ borderRadius: ".5rem", marginBottom: "0rem" }}
                >
                  <ListItemIcon
                    style={{
                      // maxWidth: "1rem",
                      margin: "auto",
                    }}
                  >
                    {path === menuItem.rootpath ? (
                      <img
                        style={{
                          width: "23px",
                          height: "auto",
                          color: path === menuItem.rootpath ? "black" : "#8E92BC",
                        }}
                        src={menuItem?.activeIcon}
                      />
                    ) : (
                      <img
                        style={{
                          width: "23px",
                          height: "auto",
                          color: path === menuItem.rootpath ? "black" : "#8E92BC",
                        }}
                        src={menuItem?.icon}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "14px",
                        fontWeight: 400,
                        // color: "#8E92BC",
                        color: menuItem.rootpath === "logout" ? "#EA0234" : path === menuItem.rootpath ? "white" : "#00172D",
                      },
                    }}
                    primary={menuItem.text}
                  />

                </ListItem>


              </React.Fragment>
            )
          ))}
        </div> */}
      </List>
      <div
        style={{
          width: `calc(${drawerWidth} - 2rem)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <HelpCenter /> */}
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">

      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.open}
          onClose={props.closeEvent}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default MainDrawer;
