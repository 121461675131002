import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextField from "../../MuiComponent/TextField";
import { apiClient } from "../../config/apiClient";
import { menu } from "../Menu";
import { DEVICE_ID } from "../../config/constants";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { loginValidation } from "../../validations/login-validate";

// const useStyles = makeStyles(theme => ({
//     // inputLabel: {
//     //     color: theme.palette.custom.heading,
//     //     fontSize: "14px",
//     //     fontWeight:600
//     // },
//     mainHeading: {
//         fontSize: '22px',
//         fontWeight: 500,
//         color: '#825ffc',
//         padding: '10% 10% 0 10%'
//     },
//     subHeading: {
//         fontSize: '24px',
//         fontWeight: 700,
//         color: 'black',
//         padding: '7% 0 7% 10%'
//     },
//     emailField: {
//         padding: '0 10% 7% 10%',
//         height: '80px',
//     },
//     passwordField: {
//         padding: '0 10% 1% 10%',
//         height: '60px',
//     },
//     forgotPassword: {
//         margin: '0 10% 0 0',
//         textDecoration: 'none',
//         // color: '#505050',
//         color: theme.palette.custom.heading,
//         fontSize: "14px",
//         fontWeight:600
//     },
//     loginButton: {
//         marginTop: '12%',
//         fontWeight: '600' ,
//         borderRadius: '10px',
//         width: '60%',
//         height: '50%'
//     }
// }))

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontSize: "24px",
    fontWeight: 500,
    color: "white",
    padding: "10% 10% 0 10%",
  },
  subHeading: {
    fontSize: "22px",
    fontWeight: 600,
    color: "white",
    padding: "7% 0 7% 10%",
  },
  emailField: {
    padding: "0 10% 0% 10%",
    height: "60px",
  },
  passwordField: {
    margin: "0% 10% 1% 10%",
    height: "40px",
    width: "80%",
  },
  forgotPassword: {
    margin: "2% 10% 0 0",
    textDecoration: "none",
    color: "white",
    // color: theme.palette.custom.heading,
    fontSize: "14px",
    fontWeight: 400,
  },
  loginButton: {
    marginTop: "8%",
    marginBottom: "8%",
    fontWeight: "600",
    borderRadius: "10px",
    width: "80%",
    height: "50%",
  },
  icon: {
    backgroundColor: "#141522",
  },

  RecaptchaDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "1rem auto 0",
  },
}));

function Login(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const notification = useSnackbar();
  const loginApiStatus = useCallbackStatus();

  const [showPassword, setShowPassword] = useState(false);
  const [mfaModal, setMFAModal] = useState(false);
  const [mfaError, setMFAError] = useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isVerified, setVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);

  const handleRecaptchaVerify = (response) => {
    console.log("Recaptcha Response", response);
    setVerified(true);
  };

  const handleClick = () => {
    setIsButtonDisabled(true);
  };

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: () => {
        // if (isVerified) {
        login();
        // } else {
        //   setReCaptchaError(true);
        // }
      },
      validationSchema: loginValidation,
    });

  const classes = useStyles();
  let { from } = location.state || { from: { pathname: "/" } };

  const apiSource = useCancelRequest();
  // const loginApiStatus = useCallbackStatus();
  const logoutApiStatus = useCallbackStatus();

  // let logoutUser = async (data) => {
  //   try {
  //     await logoutApiStatus.run(
  //       apiClient("POST", "common", "logoutuser", {
  //         body: {
  //           user_id: data.admin_id,
  //           login_id: [data.id],
  //           user_type: "admin",
  //         },
  //         shouldUseDefaultToken: true,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       }).then((result) => {
  //         login();
  //         console.log("RESULT: ", result);
  //       })
  //     );
  //   } catch (error) {
  //     console.log("ERROR: ", error);
  //     notification.enqueueSnackbar(error.message, {
  //       variant: "error",
  //     });
  //   }
  // };

  let MFASendOTP = async (apiBody) => {
    try {
      const result = await loginApiStatus.run(
        apiClient("POST", "common", "sendresendotp", {
          body: {
            user_id: apiBody.user_id,
            entity_type: "admin",
            action_type: "login",
            device_id: DEVICE_ID,
          },
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      let {
        content: { data, wait_time },
      } = result;

      navigate("/otp-verification", {
        from: "MFA",
        waitTime: wait_time,
        user_id: apiBody.user_id,
        to: from,
        data: apiBody.data,
        back_action: "/login",
      });
    } catch (error) {
      console.log("Forgot Password Component Error: ", error);

      // notification.enqueueSnackbar(error.message, {
      //   variant: "error",
      // });
    }
  };

  let login = async () => {
    await loginApiStatus.run(
      apiClient("POST", "admin", "/unauth/adminlogin", {
        body: {
          ...values,
          // device_name: DEVICE_NAME,
          // device_id: DEVICE_ID,
        },
        shouldUseDefaultToken: true,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          const {
            content: { data },
          } = result;
          console.log("login result")
          console.log(result);

          if (data.mfa_required == 1) {
            MFASendOTP({
              user_id: data.id,
              entity_type: "admin",
              action_type: "login",
              device_id: DEVICE_ID,
              data: data,
            });
          } else {
            auth.setUser(data);
            console.log("Result: ", JSON.stringify(data));
            // enqueueSnackbar("Login Successfully", {
            //   variant: "success",
            // });
            notification.enqueueSnackbar("Login Successfully", {
              variant: "success",
            });

            let activePages = data.active_pages.map((page) => page.page);
            var getActiveMenu = menu.filter((menu) =>
              activePages.includes(menu.textCompare)
            )[0];
            var links =
              getActiveMenu.child.length > 0
                ? getActiveMenu.child[0].url
                : getActiveMenu.url;

            // var count = 0;
            // for (var i = 0; i < menu.length; i++) {
            //   for (var l = 0; l < data.active_pages.length; l++) {
            //     if (menu[i].text === data.active_pages[l].page) {
            //       count++;
            //       if (count === 1) {
            //         links = menu[i].url;
            //         if(menu[i].child.length > 0){
            //           links = menu[i].child[0].url
            //         }
            //       }
            //     }
            //   }
            // }
            // alert(links);
            console.log("links")
            console.log(links)
            localStorage.setItem("rootPath", links);
            debugger

            // localStorage.setItem(
            //   "admin_support_chat_id",
            //   data.admin_support_chat_id
            // );
            window.location.href = links;
            // window.location.href = "/users";

            // setTimeout(() => {
            //   if (auth.getUserId()) {
            //     navigate("/overview");
            //   }
            // }, 1000);
          }
        })
        .catch((error) => {
          if (
            error.message ==
            "Number of concurrent active sesion exceeds limit. Please end one of the active sessions."
          ) {
            setMFAError(error.sessions);
            setMFAModal(true);
          } else {
          }
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  };

  return (
    <>
      <OuterLayout style={{ color: "white" }}>
        <SnackbarProvider />

        <Box className={classes.mainHeading}>Welcome to Medical Circles</Box>
        <Box className={classes.subHeading}>
          Login
          <Box style={{ fontWeight: 200, fontSize: "13px", marginTop: "10px" }}>
            Please enter your email address
          </Box>
        </Box>
        <Box>
          <TextField
            id="email"
            name="email"
            error={touched.email && errors.email}
            helperText={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            variant="outlined"
            placeholder="Email ID"
            className={classes.emailField}
          // inputProps={{
          //   style: {
          //     backgroundColor: "#FFFFFF",
          //     color: "#141522",
          //   },
          // }}
          />
        </Box>
        <Box>
          <TextField
            id="password"
            name="password"
            error={touched.password && errors.password}
            helperText={touched.password && errors.password}
            onChange={handleChange}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                submitForm();
              }
            }}
            onBlur={handleBlur}
            value={values.password}
            variant="outlined"
            className={classes.passwordField}
            InputProps={{
              endAdornment: (
                <IconButton
                  color="primary"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword((prevValue) => !prevValue)}
                >
                  {!showPassword ? (
                    <VisibilityOff fontSize="small" className={classes.icon} />
                  ) : (
                    <Visibility fontSize="small" className={classes.icon} />
                  )}
                </IconButton>
              ),
            }}
            // inputProps={{
            //   style: {
            //     backgroundColor: "#303652",
            //     color: "white",
            //   },
            // }}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
          />
        </Box>

        <Box style={{ textAlign: "right", marginTop: "1rem" }}>
          <Link className={classes.forgotPassword} to="/forgot-password">
            Forgot Password?
          </Link>
        </Box>

        <Box style={{ textAlign: "center" }}>
          <Button
            disabled={loginApiStatus.isPending}
            onClick={() => {
              submitForm();
              handleClick();
            }}
            variant="contained"
            className={classes.loginButton}
            color="primary"
            disable={isButtonDisabled}
          >
            {loginApiStatus.isPending ? (
              <CircularProgress size={23} color="secondary" />
            ) : (
              "Login"
            )}
          </Button>
        </Box>
      </OuterLayout>
    </>
  );
}

export default Login;
