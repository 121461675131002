import logout from "../../assets/LeftSideBarIcons/logout.svg";
import profile from "../../assets/LeftSideBarIcons/profile.png";
import activeProfile from "../../assets/LeftSideBarIcons/activeProfile.png";
import management from "../../assets/LeftSideBarIcons/management.png";
import activeManagement from "../../assets/LeftSideBarIcons/activeManagement.png";
import speaker from "../../assets/LeftSideBarIcons/speaker.png";
import activeSpeaker from "../../assets/LeftSideBarIcons/activeSpeaker.png";
import ticket from "../../assets/LeftSideBarIcons/ticket.png";
import activeTicket from "../../assets/LeftSideBarIcons/activeTicket.png";
import company from "../../assets/LeftSideBarIcons/company.png";
import activeCompany from "../../assets/LeftSideBarIcons/activeCompany.png";
import profession from "../../assets/LeftSideBarIcons/profession.png";
import activeProfession from "../../assets/LeftSideBarIcons/activeProfession.png";
import category from "../../assets/LeftSideBarIcons/category.svg";
import _ from "lodash";


const hasAccess = (page) => {
    let activePages = [];
    activePages = JSON.parse(localStorage.getItem("activePages"));

    if (!_.isNull(activePages) && (activePages.includes(page) || page === "Yes")) {
        return true;
    } else return false;
};

const menu = [
    {
        text: "Overview", //Name of List Item
        hasAccess: hasAccess("Dashboard"),
        textCompare: "Dashboard",
        icon: category, //Icon of List Item
        activeIcon: category, //Icon of List Item
        rootpath: "overview", //RootPath used for selected view
        url: "/overview", //After Click where you want to redirect
        active: false,
        child: [],
    },
    {
        text: "Management",
        textCompare: "User Management",
        hasAccess: hasAccess("User Management"),
        icon: management,
        activeIcon: activeManagement,
        rootpath: "users",
        url: "/users",
        active: false,
        child: [],
    },
    {
        text: "User Requests",
        textCompare: "User Requests",
        hasAccess: hasAccess("User Requests"),
        icon: ticket,
        activeIcon: activeTicket,
        rootpath: "user_requests",
        url: "/user_requests",
        active: false,
        child: [],
    },
    {
        text: "Role Access",
        textCompare: "Role Access",
        hasAccess: hasAccess("Role Access"),
        icon: ticket,
        activeIcon: activeTicket,
        rootpath: "role_access",
        url: "/role_access",
        active: false,
        child: [],
    },
    {
        text: "Support Tickets",
        textCompare: "Support Tickets",
        icon: ticket,
        hasAccess: hasAccess("Support Tickets"),
        activeIcon: activeTicket,
        rootpath: "support_tickets",
        url: "/support_tickets",
        active: false,
        child: [],
    },
    {
        text: "Query",
        textCompare: "Query",
        icon: ticket,
        hasAccess: hasAccess("Support Tickets"),
        activeIcon: activeTicket,
        rootpath: "query",
        url: "/query",
        active: false,
        child: [],
    },
    // {
    //   text: "Groups",
    //   textCompare: "Groups",
    //   icon: ticket,
    //   activeIcon: activeTicket,
    //   rootpath: "groups",
    //   url: "/groups",
    //   active: false,
    //   child: [],
    // },
    {
        text: "Announcements",
        textCompare: "Announcements",
        hasAccess: hasAccess("Announcements"),
        icon: speaker,
        activeIcon: activeSpeaker,
        rootpath: "announcements",
        url: "/announcements",
        active: false,
        child: [],
    },
    {
        text: "Profession Settings",
        textCompare: "Profession settings",
        hasAccess: hasAccess("Profession settings"),
        icon: profession,
        activeIcon: activeProfession,
        rootpath: "settings",
        url: "/settings",
        active: false,
        child: [],
    },
    {
        text: "Company Info",
        textCompare: "Compan Info",
        hasAccess: hasAccess("Compan Info"),
        icon: company,
        activeIcon: activeCompany,
        rootpath: "company",
        url: "/company",
        active: false,
        child: [
            // {
            //   text: "Contact Us",
            //   textCompare: "Contact Us",
            //   icon: null,x
            //   rootpath: "company/contact",
            //   active: false,
            //   url: "/company/contact",
            // },
            // {
            //   text: "About Us",
            //   textCompare: "About Us",
            //   icon: null,
            //   rootpath: "company/about",
            //   active: false,
            //   url: "/company/about",
            // },
            {
                text: "Privacy Policy",
                textCompare: "Privacy Policy",
                icon: null,
                rootpath: "company/privacy-policy",
                active: false,
                url: "/company/privacy-policy",
            },
            {
                text: "Terms of Use",
                textCompare: "Terms of Use",
                icon: null,
                rootpath: "company/termsofuse",
                active: false,
                url: "/company/termsofuse",
            },
        ],
    },
    // {
    //   text: "Operation",
    //   textCompare: "Operation",
    //   icon: mesasge,
    //   rootpath: "operation",
    //   url: "/operation",
    //   active: false,
    //   child: [],
    // },
    // {
    //   text: "Accessibility",
    //   textCompare: "Accessibility",
    //   icon: accessibility,
    //   rootpath: "accessibility",
    //   url: "/accessibility",
    //   active: false,
    //   child: [],
    // },

    {
        text: "Profile",
        textCompare: "Profile",
        hasAccess: hasAccess("Yes"),
        icon: profile,
        activeIcon: activeProfile,
        rootpath: "profile",
        url: "/profile",
        active: false,
        child: [],
    },
    {
        text: "Logout",
        textCompare: "Logout",
        hasAccess: hasAccess("Yes"),
        icon: logout,
        activeIcon: logout,
        rootpath: "logout",
        url: "/logout",
        active: false,
        child: [],
    },
];
export { menu };
