import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Datatables from "../components/Datatables";
import Header from "../layouts/Header"

import useCancelRequest from "../hooks/useCancelToken";

import { Box, Switch } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import useGeneralStyle from "../assets/css/general";
import { apiClient } from "../config/apiClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useAuth } from "../providers/AuthProvider";
// import CreateRankModal from "./AnnouceModal";

const tableHeader = [
    { id: "Created Date", align: "left", label: "Created at", minWidth: 120 },
    { id: "Created Date", align: "left", label: "First name", minWidth: 120 },
    { id: "Created By", align: "left", label: "Last name", minWidth: 120 },
    { id: "ANNOUNCEMENT", align: "left", label: "Email", minWidth: 120 },
    { id: "ANNOUNCEMENT", align: "left", label: "Organization", minWidth: 120 },
    { id: "ANNOUNCEMENT", align: "left", label: "Job Title", minWidth: 120 },
    { id: "ANNOUNCEMENT", align: "left", label: "Phone number", minWidth: 120 },
    { id: "ANNOUNCEMENT", align: "left", label: "Source", minWidth: 120 },





    //   { id: "Action", align: "left", label: "Action", minWidth: 170 },
];

const useStyles = makeStyles((theme2) => ({
    formControl: {
        margin: theme2.spacing(1),
        minWidth: 160,
        color: "white",
        borderBottom: "none",
    },
    selectEmpty: {
        marginTop: theme2.spacing(2),
    },
    icon: {
        fill: "white",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        // marginTop: '25px',
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    rejectedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    pendingStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #eab20a",
        backgroundColor: "#ffeeb5",
        color: "#eab20a",
        fontWeight: "bold",
    },
    registeredStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #6ac358",
        backgroundColor: "#c3f4ba",
        color: "#6ac358",
        fontWeight: "bold",
    },
    container: {
        height: "440px",
        width: "100%",
    },
    activeFilter: {
        // backgroundColor: theme2.palette.primary.light,
        borderBottom: "2px solid white ",
        borderRadius: "0px",
        color: "white",
        fontWeight: 600,
    },
    icon: {
        fill: "#FFFFFF",
    },
    carddelModal: {
        width: "500px",
        borderRadius: "12px",
        backgroundColor: "#1b1f3b",
        padding: "1rem",
        position: "relative",

        [theme2.breakpoints.down("sm")]: {
            width: "90vw !important",
        },

        "&:focus-visible": {
            outline: "none",
        },
    },
    profileData: {
        color: "white",
        paddingTop: "2px",
        fontSize: "16px",
        // fontWeight: 600,
    },
    profileHeading: {
        fontSize: "12px",
        // fontWeight: 600,
        color: "#B0B2B6",
        textAlign: "left",
    },

    parentofappointmentcontent: {
        [theme2.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    bottombox: {
        [theme2.breakpoints.down("xs")]: {
            marginLeft: "0 !important",
            display: "flex",
            justifyContent: "flex-start !important",
            alignItems: "flex-start !important",
        },
    },
    doublerarrow: {
        [theme2.breakpoints.down("xs")]: {
            transform: "rotate(90deg)",
            margin: "1rem !important",
        },
    },

    tabsContainer: {
        backgroundColor: "#fff",
        margin: "20px 20px 0px 20px",
        height: "44px",
        [theme2.breakpoints.down("md")]: {
            width: "95%",
            overflow: "scroll",
        },
        [theme2.breakpoints.down("sm")]: {
            width: "88%",
            overflow: "scroll",
        },
        "&::-webkit-scrollbar": {
            height: "0",
        },
    },
}));

const Ranks = () => {
    const globalClasses = useGeneralStyle();
    const classes = useStyles();

    // ------------------------ Toggle Switch -------------------

    const ToggleSwitch = withStyles((theme) => ({
        root: {
            width: 36,
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#141522",
                    opacity: 1,
                    border: "none",
                },
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: "50px",
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: "#7E7E7E",
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });

    // ==================== API LOGIC ======================

    const [totalPages, setTotalPages] = useState(0);

    const [rank, setRank] = useState("");
    const [search, setSearchKey] = useState("");
    const [create, setCreate] = useState(false);
    const notification = useSnackbar();
    const [rankData, setrankData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const auth = useAuth();
    const apiSource = useCancelRequest();
    const [currentRow, setCurrentRow] = useState(null);
    const createRankApiStatus = useCallbackStatus();
    const getRankListApiStatus = useCallbackStatus();
    const manageRankApiStatus = useCallbackStatus();
    const updateRankApiStatus = useCallbackStatus();

    const getRankList = async (token, refresh) => {
        let apiBody = {};
        // if (search) {
        //     apiBody.search_string = search;
        //     apiBody.search_fields = ["name"];
        // }
        await getRankListApiStatus.run(
            apiClient("POST", "common", "/unauth/thirdpartyregistration", {
                body: {
                    action: "list",
                    pagination_required: true,
                    next_token: token,
                    // ...apiBody,
                },
                shouldUseDefaultToken: true,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log("data from api query", data);
                    if (refresh) setrankData([...data]);
                    else {
                        setrankData([...rankData, ...data]);
                    }
                    // setrankData(data);
                    setHasMore(has_more);
                    setTotalPages(next_token);
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };

    const createRank = async (action) => {
        let apiBody = {
            action: action,
            entity_type: "announcement",
            entity_name: rank,
        };
        if (action === "update") {
            apiBody.entity_id = currentRow.id;
        }

        await createRankApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: { ...apiBody },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log(result);
                    setCreate(false);
                    setCurrentRow(null);
                    getRankList(null, true);
                    notification.enqueueSnackbar("Successfully updated rank", {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };
    // ------------------ update rank ---------------

    // const updateRank = async (row) => {
    //   let apiBody = {
    //     action: "update",
    //     entity_type: "rank",
    //     entity_id: row.id,
    //     entity_name: row.name,
    //   };

    //   row.is_enabled = !row.is_enabled;
    //   apiBody.is_enabled = !row.is_enabled;

    //   console.log("update rank apibody", apiBody);

    //   await updateRankApiStatus.run(
    //     apiClient("POST", "common", "manageentities", {
    //       body: {
    //         ...apiBody,
    //       },
    //       shouldUseDefaultToken: false,
    //       cancelToken: apiSource.token,
    //       enableLogging: true,
    //     })
    //       .then((result) => {
    //         getRankList(true);
    //         const {
    //           content: { data, has_more, next_token },
    //         } = result;
    //       })
    //       .catch((err) => {
    //         if (err && err.code === 403) {
    //           auth.logout();
    //         } else {
    //           notification.enqueueSnackbar(err.message, {
    //             variant: "err",
    //             autoHideDuration: 2000,
    //           });
    //         }
    //       })
    //   );
    // };

    // ------------------ manage rank ---------------

    const manageRank = async (row) => {
        let apiBody = {
            action: "update",
            entity_type: "rank",
            entity_id: row.id,
            is_enabled: row.is_enabled,
        };
        apiBody.is_enabled = !row.is_enabled;

        console.log(apiBody, "enable edit");
        await manageRankApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    ...apiBody,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    getRankList(null, true);
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log("data from api", data);
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };
    useEffect(() => {
        getRankList(null, true);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getRankList(null, true);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            {/* {create ? (
        <CreateRankModal
          setCreate={setCreate}
          setRank={setRank}
          rank={rank}
          createRank={createRank}
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
        />
      ) : null} */}
            <Header />

            <Grid container>
                <Grid
                    container
                    style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "20px",
                    }}
                    className="ChangeRequestsContainer"
                >
                    <Grid
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={5}
                        md={5}
                        lg={5}
                    >
                        <TextField
                            onChange={(e) => setSearchKey(e.target.value)}
                            variant="outlined"
                            style={{ backgroundColor: "#F5F5F7", width: "400px" }}
                            inputProps={{
                                style: { padding: "12px" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        variant="filled"
                                        fontSize="small"
                                        style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                                    />
                                ),
                                className: "inputFont",
                            }}
                            placeholder="Search"
                        />
                    </Grid>
                    {/* <Grid
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "10px 0px",
                            marginRight: "10px",
                        }}
                        item
                        xs={11}
                        sm={2}
                        md={2}
                        lg={2}
                    >
                        <Button
                            variant="outlined"
                            style={{
                                color: "white",
                                backgroundColor: "#141522",
                            }}
                            onClick={() => {
                                setCreate(true);
                            }}
                        >
                            Create
                        </Button>
                    </Grid> */}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Paper className={classes.root}>
                        <Datatables
                            data={rankData}
                            loading={getRankListApiStatus.isPending}
                            getDataList={(token) => getRankList(token, false)}
                            hasMore={hasMore}
                            nextToken={totalPages}
                            tableHeader={tableHeader}
                            orderByKey="name"
                            isEmpty={rankData.length === 0}
                        >
                            {rankData.map((row, index) => {
                                return (
                                    <TableRow key={index} hover>
                                        {/* <TableCell>{row.name}</TableCell> */}
                                        <TableCell>
                                            {moment
                                                .unix(row.created_at)
                                                .format("Do MMM YY, hh:mm A")}                                            {/* {row.first_name} */}
                                        </TableCell>
                                        <TableCell>
                                            {/* {moment.unix(row.created_at).format("Do MMM YYYY")} */}
                                            {row.first_name}
                                        </TableCell>
                                        <TableCell>{row.last_name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.organization_name}</TableCell>
                                        <TableCell>{row.job_title}</TableCell>
                                        <TableCell>{row.phone_number}</TableCell>
                                        <TableCell>{row.registration_type === "feedback" ? "Schedule a demo" : 'Contact Us'}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </Datatables>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Ranks;
