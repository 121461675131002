import { TextField as MuiTextField } from "@material-ui/core";
import React from "react";


function TextField(props) {
  return (
    <MuiTextField
      fullWidth
      inputProps={{
        style: {
          padding: "10px",
          backgroundColor: "#FFFFFF",
          color: "#141522",
          fontFamily:'"Plus Jakarta Sans", sans-serif'
        },
      }}
      {...props}
    />
    // <MuiTextField fullWidth inputProps={{style:{padding:"12px"}}}  {...props} />
  );
}

export default TextField;
