import {
  Box,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";
import { KeyboardBackspace as KeyboardBackspaceIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import medicalcircles from "../../assets/LeftSideBarIcons/medicalcircles.svg";

// import logoText from "../../assets/images/logo/logo-text.svg";

const useStyles = makeStyles((theme) => ({
  // root: {
  //     height: "100vh",
  // },
  // image: {
  //     display: "flex",
  // },
  // backgroundImage:{
  //     position:"absolute",
  //     margin: '0px',
  //     left: '0px',
  //     backgroundColor: '#131b46',
  //     zIndex: 1,
  //     width: '100%',
  //     height: '100%'
  // },
  // logoImage: {
  //     backgroundColor: '#131b46',
  //     zIndex: 2,
  //     position: 'absolute',
  //     left: 0,
  //     top: '20px'
  // },
  // paper: {
  //     margin: theme.spacing(8, 4),
  //     display: "flex",
  //     flexDirection: "column",
  //     width: '50%',
  //     backgroundColor:'white',
  //     height: '70vh',
  //     marginTop: '20vh',
  //     borderRadius: '16px',
  //     width: '70%',
  //     zIndex: 3
  // },
  // backArrow: {
  //     margin: '2% 0 1% 7%',
  // },
  root: {
    height: "100vh",
    "& svg": {
      fill: "#fff",
    },
  },
  image: {
    display: "flex",
  },
  backgroundImage: {
    position: "absolute",
    margin: "0px",
    left: "0px",
    backgroundColor: "#FFFFFF",
    zIndex: 1,
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0rem",
      margin: "0rem",
    },
  },
  logoImage: {
    backgroundColor: "#FFFFFF",
    zIndex: 2,
    position: "absolute",
    // left: "30px",
    margin: " 250px 250px",
    // top: "20px",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#141522",
    border: "1px solid #404e9a",
    marginTop: "10vh",
    borderRadius: "16px",
    width: "60%",
    zIndex: 3,
    position: "relative",
    left: "20%",

    [theme.breakpoints.down("md")]: {
      margin: "0",
      // maxHeight: "90vh",
      position: "relative",
      float: "right",
      right: "0%",
      top: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5rem 2rem 0 ",
      width: "50vw",
      left: "0%",
      top: "0%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      minHeight: "100vh",
      // position: "relative !important",
      // top: "20% !important",
      // left: "10%",
      borderRadius: "0px",
      border: "none",
      margin: "0 0 0 0 ",
      paddingTop: "2rem",
    },
  },
  backArrow: {
    margin: "2% 0 1% 7%",
  },
}));

function OuterLayout(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(props.on_back);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;

  return (
    // <Grid container component='main' className={classes.root}>
    //     <CssBaseline />

    //     <Grid style={{zIndex: 2}} item xs={12} sm={8} md={6} elevation={4} square container alignItems="center">

    //         <Grid item xs={12}>

    //             <div className={classes.paper}>
    //                 {props.is_back_button && !isMobile ?
    //                     <Box className={classes.backArrow}>
    //                         <IconButton onClick={handleBackClick}>
    //                             <KeyboardBackspaceIcon />
    //                         </IconButton>
    //                         <Typography style={{ fontWeight: 500 }}>
    //                             &nbsp;
    //                         </Typography>
    //                     </Box>
    //                 : null}
    //                 {props.children}
    //             </div>
    //         </Grid>
    //     </Grid>
    //     <Grid item xs={12} sm={4} md={6} className={classes.image}>
    //         <Box
    //             className={classes.logoImage}
    //             onClick={()=> history.push("/login")}
    //         >
    //             <img src={logo} alt="connected-minds-logo" />
    //             {/* <img src={logoText} alt="connected-minds-logo-text" /> */}
    //         </Box>
    //         <Box className={classes.backgroundImage}>
    //             <img height='100%' width='100%' src={backgroundImage} alt="Brand" />
    //         </Box>
    //     </Grid>
    // </Grid>

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={4} md={6} className={classes.image}>
        {!isMobile ? (
          <Box className={classes.logoImage} onClick={() => navigate("/login")}>
            <img
              src={medicalcircles}
              alt="MedicalCircles"
              draggable="false"
              style={{
                height: "100px",
                cursor: "default",
                userSelect: "none",
                userDrag: "none",
              }}
            />
            {/* <img src={logoText} alt="connected-minds-logo-text" /> */}
          </Box>
        ) : null}
        <Box className={classes.backgroundImage}>
          {/* <img
            height="100%"
            width="100%"
            src={backgroundImage}
            alt="background"
          /> */}
        </Box>
      </Grid>
      <Grid
        style={{ zIndex: 2 }}
        item
        xs={12}
        sm={8}
        md={6}
        elevation={4}
        square
        container
        alignItems="center"
      >
        <Grid item xs={12}>
          <div className={classes.paper}>
            {props.is_back_button && !isMobile ? (
              <Box className={classes.backArrow}>
                <IconButton onClick={handleBackClick}>
                  <KeyboardBackspaceIcon style={{ color: "white" }} />
                </IconButton>
                <Typography style={{ fontWeight: 500 }}>&nbsp;</Typography>
              </Box>
            ) : null}
            {props.children}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OuterLayout;
