import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText } from "@mui/material";

const SuspendAdminModal = ({
  setReason,
  setAdminComment,
  adminComment,
  setCurrentStatus,
  updateAdminList,
}) => {
  const [errors, setErrors] = React.useState(null);

  const handleClose = () => {
    setReason(false);
  };

  const handleSave = () => {
    if (!adminComment) {
      setErrors("Please enter reason");
    } else {
      setCurrentStatus("suspended");
      setReason(false);
      updateAdminList();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth onClose={handleClose}>
        <DialogTitle>Suspension Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the reason for rejecting</DialogContentText>
          <br />
          <TextField
            variant="outlined"
            autoFocus
            error={errors}
            id="name"
            fullWidth
            onChange={(e) => {
              setErrors(null);
              setAdminComment(e.target.value);
            }}
            multiline
            rows={5}
          />
          <FormHelperText style={{ color: "red" }}>{errors}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default SuspendAdminModal;
