import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard({ data }) {
  return (
    <Grid container spacing={2}>
      <Grid item sx={6}>
        <Card
          sx={{
            width: "100%",
            minWidth: "200px",
            height: "160px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: "16px" }} gutterBottom>
              Total Tickets{" "}
            </Typography>

            <Typography
              sx={{ fontSize: "42px" }}
              color="#246AF1"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {data[0].total_tickets}
            </Typography>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>
      <Grid item sx={6}>
        <Card
          sx={{
            width: "100%",
            minWidth: "200px",
            height: "160px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: "16px" }} gutterBottom>
              Total Pending Requests{" "}
            </Typography>

            <Typography
              sx={{ fontSize: "45px" }}
              color="#246AF1"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {data[0].total_pending_requests}
            </Typography>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>
    </Grid>
  );
}
